import React from 'react';
import "../scss/main.scss"
import Header from '../components/header';
import Main from '../components/main';
import PageHeader from '../components/Organism/PageHeader/PageHeader';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer/Footer';
import StartWork from '../components/forms/StartWork';

function Support(props) {
    const form = <StartWork/>;
    return (
        <>
            <Helmet>
                <title>Поддержка и доработка сайтов и веб приложений</title>
                <meta name="description" content="Станем вашим надежным партнером по доработке и поддержке сайтов. Работаем с Wordpress, Django, Strapi, ReactJS, NetCat, 1c-Bitrix" />
            </Helmet>
            <Header/>
            <Main>
                <PageHeader h1={"Поддержка и доработка сайтов и веб приложений"}
                    p={"Станем вашим надёжным партнёром по доработке и поддержке сайтов"} modalButton modalContent={form}/>
            </Main>
            <Footer/>
        </>
    );
}

export default Support;